/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react';

import { Col, ConfigProvider, Flex, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

import Comet from './Comet';

const MapWithComets = ({
  fromFieldEventsDetails,
  toFieldEventsDetails,
  as_point,
}: {
  fromFieldEventsDetails: any;
  toFieldEventsDetails: any;
  as_point: boolean;
}) => {
  const fieldRef = useRef<any>(null);
  const {
    setDisplayedComets,
    setSelectedFromFieldsCells,
    setMatchedPointEvents,
  } = teamPlayerStatsSectionReducer.actions;
  const {
    multitoolEvents,
    displayedComets,
    selectedFromFieldCells,
    selectedToFieldCells,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    multitoolEventsLoading,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const FIELD_CELLS = [
    [{ id: 5 }, { id: 10 }, { id: 15 }, { id: 20 }, { id: 25 }, { id: 30 }],
    [{ id: 4 }, { id: 9 }, { id: 14 }, { id: 19 }, { id: 24 }, { id: 29 }],
    [{ id: 3 }, { id: 8 }, { id: 13 }, { id: 18 }, { id: 23 }, { id: 28 }],
    [{ id: 2 }, { id: 7 }, { id: 12 }, { id: 17 }, { id: 22 }, { id: 27 }],
    [{ id: 1 }, { id: 6 }, { id: 11 }, { id: 16 }, { id: 21 }, { id: 26 }],
  ];
  useEffect(() => {
    let final: any[] = [];
    if (selectedFromFieldCells.length || selectedToFieldCells.length) {
      if (selectedFromFieldCells) {
        final = multitoolEvents.filter(
          (evt: any) =>
            selectedFromFieldCells
              .map((cellId: number) => fromFieldEventsDetails[cellId])
              .filter((cell: any) => cell.events.includes(evt.id)).length,
        );
      }
      if (selectedToFieldCells) {
        final = [
          ...final,
          ...multitoolEvents
            .filter(
              (evt: any) =>
                selectedToFieldCells
                  .map((cellId: number) => toFieldEventsDetails[cellId])
                  .filter((cell: any) => cell.events.includes(evt.id)).length,
            )
            .filter(
              (el) =>
                final.filter((elInner) => elInner.id === el.id).length === 0,
            ),
        ];
      }
    } else {
      final = multitoolEvents;
    }
    dispatch(setDisplayedComets(final));
  }, [selectedFromFieldCells, selectedToFieldCells, multitoolEvents]);
  const toggleMapCell = (cellId: number) => {
    let updated = [];
    if (selectedFromFieldCells.includes(cellId)) {
      updated = selectedFromFieldCells.filter((el) => el !== cellId);
    } else {
      updated = [...selectedFromFieldCells, cellId];
    }
    const final = multitoolEvents.filter(
      (evt: any) =>
        updated
          .map((cellId: number) => fromFieldEventsDetails[cellId])
          .filter((cell: any) => cell.events.includes(evt.id)).length,
    );
    dispatch(setMatchedPointEvents(final));
    dispatch(setSelectedFromFieldsCells(updated));
  };
  const constructCellClassName = (
    details: any,
    cellId: number,
    selectedCells: number[],
    oppositeCells: number[],
  ) => {
    let className = 'field-cell';
    // console.log(cell);
    if (as_point && details[cellId]) {
      if (details[cellId]['events'].length) {
        className += ' with-value';
      }
      if (selectedCells.includes(cellId)) {
        className += ' selected';
      } else if (
        oppositeCells &&
        oppositeCells.filter((value) =>
          details[cellId]['opposite'].includes(value),
        ).length
      ) {
        className += ' opposite-selected';
      } else if (
        (selectedCells?.length && !selectedCells.includes(cellId)) ||
        (oppositeCells?.length && !selectedCells.includes(cellId))
      ) {
        className += ' non-selected';
      }
    }
    return className;
  };
  const getBackgroundColor = (
    cellDetails: any,
    cell: any,
    selectedCells: any,
    oppositeCells: any[],
  ) => {
    let baseColor;
    if (as_point) {
      if (oppositeCells.length) {
        if (selectedCells?.length && selectedCells.includes(cell.id)) {
          baseColor = `rgba(22, 104, 220, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
        } else if (
          cellDetails[cell.id] &&
          oppositeCells &&
          oppositeCells.filter((value) =>
            cellDetails[cell.id]['opposite'].includes(value),
          ).length
        ) {
          // opposite-selected
          baseColor = `rgba(22, 104, 220, ${0.3 * cellDetails[cell.id]?.opacity + 0.2 || 0})`;
        } else {
          // opposite-not-selected
          baseColor = `rgba(0, 0, 0, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
        }
      } else {
        if (selectedCells?.length && !selectedCells.includes(cell.id)) {
          // non-selected
          baseColor = `rgba(0, 0, 0, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
        } else {
          // with-value
          baseColor = `rgba(22, 104, 220, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
        }
      }
      // console.log(cell);
    }
    return baseColor;
  };
  return (
    <Flex>
      <Flex vertical gap={6} style={{ width: '100%' }}>
        <div
          ref={fieldRef}
          className={`field-class ${multitoolEventsLoading ? '' : ''}`}
          style={{ position: 'relative' }}
        >
          {FIELD_CELLS.map((row, index) => {
            return (
              <Row style={{ height: '20%' }} key={index}>
                {row.map((cell) => {
                  return (
                    <Col
                      span={4}
                      className={constructCellClassName(
                        fromFieldEventsDetails,
                        cell.id,
                        selectedFromFieldCells,
                        selectedToFieldCells,
                      )}
                      key={cell.id}
                      onClick={() => {
                        as_point &&
                          fromFieldEventsDetails[cell.id] &&
                          fromFieldEventsDetails[cell.id]['events']?.length &&
                          toggleMapCell(cell.id);
                      }}
                      style={{
                        backgroundColor: getBackgroundColor(
                          fromFieldEventsDetails,
                          cell,
                          selectedFromFieldCells,
                          selectedToFieldCells,
                        ),
                      }}
                    >
                      <span className="fs-12 lh-20 pl4">{cell.id}</span>
                      <div className="value">
                        {(as_point &&
                          fromFieldEventsDetails[cell.id] &&
                          fromFieldEventsDetails[cell.id]['events']?.length) ||
                          ''}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
          <Flex
            style={{
              position: 'absolute',
              zIndex: 20,
              top: 'calc(50% - 15px)',
              left: 'calc(50% - 25px)',
            }}
          >
            <ConfigProvider>
              <Spin
                spinning={multitoolEventsLoading}
                // spinning={true}
                size="large"
                tip={t('Loading')}
              >
                <div style={{ padding: '25px' }}></div>
              </Spin>
            </ConfigProvider>
          </Flex>
          {displayedComets.map((comet: any) => (
            <Comet
              key={comet.id}
              comet={comet}
              bounds={fieldRef?.current?.getBoundingClientRect()}
            />
          ))}
        </div>
      </Flex>
    </Flex>
  );
};
export default MapWithComets;
